.filters-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-around;
}

.filters-container > a {
    background-color: transparent;
    padding: 0.5rem 1.5rem;
    margin: 0.5rem 1rem;
    transition: 1s;
}

.filters-container a.selected {
    background-color: pink;
    border-radius: 10px 15px;
}

@media (hover: hover) {
    .filters-container > a:hover {
        text-shadow: 1px 1px 1px #000000;
        color: var(--color-text-hl);
    }

    .filters-container > a.selected:hover {
        color: var(--color-text-hl);
    }
}

@media screen and (max-width: 600px) {
    .filters-container > a {
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .filters-container > a:first-child {
        margin: 0 5rem;
        margin-bottom: 0.5rem;
    }
}