#contact {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    min-height: inherit;
}

#contact > * {
    padding: 0!important; 
}

.contact-info {
    text-align: center;
}

.email {
    padding-top: 2rem;
    display:flex;
    font-size: 2rem;
}

@media screen and (max-width: 600px) {
    .email {
        font-size: 1rem;
    }
}