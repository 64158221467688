.modal {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-body {
  justify-content: center;
  display: flex;
  position: relative;
}

.modal-body-not-loaded {
  display: none;
}

iframe {
  max-width: 100%;
  min-width: 65vw;
  max-height: 90vh;
  height: auto;
  aspect-ratio: 16/9;
}

.modal-content {
  display: flex;
  position: relative;
  margin: auto 0;
  padding: 0;
  animation-name: animatetop;
  animation-duration: 0.4s;
  max-height: 90vh;
}

#modal-image {
  max-height: 90vh;
}

.image-magnifier-portrait {
  margin-left: auto;
}

.close-button {
  position: fixed;
  top: 3rem;
  right: 5rem;
  cursor: pointer;
  font-size: 2rem;
  color: blueviolet;
}

.close-button.info-portrait {
  right: 7rem;
}

.info-icon.info-landscape {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  font-size: 3rem;
  border-radius: 20px;
  background-color: var(--color-primary-variant-transparent);
  animation: colorful 2s ease-in-out infinite alternate;
}

.info-icon.info-portrait {
  display: none;
}

.content-info-text {
  opacity: 0;
  display: block;
  padding: 2rem 2rem;
  color: rgb(253, 255, 141);
  background-color: rgba(152, 72, 168, 0.8);
  border-radius: 20px;
  transition: 1.5s;
}

.content-info-text.info-landscape {
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.content-info-text.info-portrait {
  opacity: 1;
  display: block;
  margin-left: 4rem;
  max-width: 30%;
  height: fit-content;
  padding: 3rem 1rem;
}

.content-info-text p {
  margin-top: 1rem;
}

@keyframes colorful{
  From{color: rgb(193, 255, 78)}
To {color: deeppink}
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

@media screen and (max-width:1000px) {
  .modal {
    padding: 0;
  }

  .modal-content {
    max-height: 90vh;
  }

  #modal-image {
    max-width: 100vw;
  }

  iframe {
    width: 100vw;
    height: auto;
    aspect-ratio: 16/9;
  }
  
  .modal-body {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .close-button {
    top: 2rem;
    right: 2rem;
    font-size: 2.5rem;
  }

  .info-icon.info-landscape {
    right: auto;
    left: 10px;
  }

  .content-info-text.info-landscape {
    bottom: auto;
    transform: translate(-50%, 50%);
    z-index: auto;
  }
}

@media screen and (max-width:600px) {
  .close {
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
  }
}