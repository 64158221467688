@import url('https://fonts.googleapis.com/css2?family=DynaPuff:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #d6a9b3;
    --color-primary: #ffc9d5;
    --color-primary-variant: #ee9aff;
    --color-primary-variant-transparent: rgba(232, 116, 255, 0.5);
    --color-text: #424242;
    --color-text-hl: #cf2a69;
    --transition: 0.7s;
    --overflow-behavior: hidden;
}

body {
    margin: 0;
    font-family: 'DynaPuff', cursive;
    overflow-y: var(--overflow-behavior);
}

#main {
    background: var(--color-bg);
    background: url("/src/assets/pattern.svg");
    margin-left: 16rem;
    position: relative;
    min-height: 100vh;
}

section>* {
    padding: 1rem 5.4rem;
}

a {
    cursor: pointer;
}

p {
    font-family: 'Comic Neue', cursive;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1.1rem;
}

hr {
    border-top: 3px dashed rgb(253, 255, 141);
    border-radius: 2px;
}

.img-info-side {
    display: flex;
    align-items: center;
}

.header-title {
    width: 100%;
    padding-top: 4rem;
    text-align: center;
    font-size: 3rem;
}

.header-subtitle {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 500;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 30%;
    opacity: 0.8;
    animation: colorful 2s ease-in-out infinite alternate;
}

@keyframes colorful{
    From{color: rgb(193, 255, 78)}
To {color: deeppink}
}

.tooltiptext {
    visibility: hidden;
    max-width: 20vw;
    background-color: #443737;
    color: #fff;
    text-align: center;
    padding: 1rem 0.5rem;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

@media (hover: hover) {
    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
}

@media screen and (max-width: 1000px) {
    #main {
        margin-left: 0;
        margin-top: 10vh;
    }

    section {
        width: 100vw;
    }
}

@media screen and (max-width: 600px) {
    .img-info-side {
        flex-direction: column-reverse;
    }
    
    section>* {
        padding: 1rem 2rem;
    }
}