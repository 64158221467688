.slider {
    position: relative;
    height:100vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.slider iframe {
    height: 70vh;
}

.slider img {
    width: 100%;
    height: 100vh;
}

.slide-title {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    background: rgba(238, 154, 255, 0.7);
    border-radius: 20px;
    padding: 1rem 1.5rem;
}

.slide-ttip {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.portrait {
    object-fit: contain;
}

.landscape {
    object-fit: cover;
}

.arrow {
    position: absolute;
    top: 50%;
    padding: 0.3rem;
    font-size: 3.5rem;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    background: var(--color-primary-variant-transparent);
    color: var(--color-primary);
    transition: 1s;
}

.arrow.left {
    margin-left: 2rem;
    left: 0;
}

.arrow.right {
    margin-right: 2rem;
    right: 0;
}

.slide {
    opacity: 0;
    position: relative;
    text-align: center;
    width: 0;
    height: 0;
}

.slide.active {
    opacity: 1;
    transition: 3s;
    width: auto;
    height: auto;
}

.slide-title-icon {
    line-height: 0;
    font-size: 1.5rem;
    animation: colorful 2s ease-in-out infinite alternate;
}

@keyframes colorful{
    From{color: rgb(193, 255, 78)}
To {color: deeppink}
}

@media (hover: hover) {
    .arrow:hover {
        background: transparent;
    }

    .slide:hover .play-button {
        color: rgb(211, 43, 226);
    }
}

@media screen and (max-width:1000px) {
    .slider, .slider img {
        height: 90vh;
    }

    .slider {
        display: flex;
        align-items: center;
    }

    .portrait {
        object-fit: cover;
    }
    
    .landscape {
        height: 70vh!important;
        object-fit: cover;
    }

    .slide-ttip {
        bottom: 8%;
    }
}

@media screen and (max-width:600px) {
    .slide-title {
        padding: 0.7rem 1rem;
    }

    .arrow.left {
        margin-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .arrow.right {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .slide-ttip {
        bottom: 3%;
    }
}