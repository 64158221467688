#about {
    display: block;
}

#about:last-child {
    padding-bottom: 4rem;
}

.about-header-img {
    object-fit: cover;
    width: 100%;
    height: 30vh;
}

.about-img {
    max-height: 50vh;
    margin-left: 1.5rem;
    max-width: 40%;
}

@media screen and (max-width: 600px) {
    .about-img {
        min-width: 60vw;
        margin-left: 0;
        margin-bottom: 2rem;
    }
    
}
