.gallery-container {
    border-radius: 0.5rem;
    padding: 1rem 5.4rem 3rem 5.4rem;
    margin: 0 0;
    transition: 1.5s;
    opacity: 1;
}

.gallery-container.gallery-is-loading {
    opacity: 0;
}

.masonry-gallery {
    columns: 12rem;
    column-gap: 1rem;
    transition: 2s;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1000px) {
    .masonry-gallery {
        columns: 10rem;
    }

    .gallery-container {
        padding: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .masonry-gallery {
        columns: 8rem;
    }
}