footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    gap: 0.8rem;
}

footer p {
    font-size: 1rem;
}
